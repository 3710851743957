@import '/src/App.scss';

.create-account-container {
  display: flex;
  align-items: center;
  justify-content: center;

  .divider {
    width: 100%;
  }
}