.completed-workout-card {
  width: 100%;
  background: #94CEEB;
  border-radius: 8px;
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  overflow: hidden;
  font-family: 'Poppins';
  font-style: normal;
  color: #2D3748;

  .background-shape {
    position: absolute;
    top: 0;
    left: 0;
    fill: red;
  }

  .date {
    font-weight: 600;
    font-size: 28px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
    z-index: 10;
  }

  .additional-info {
    z-index: 1;
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
  }

  .exercise-group-names {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #54647F;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .stat-group {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    gap: 20px;

    .stat {
      display: flex;
      flex-direction: column;

      .title {
        font-size: 14px;
        line-height: 21px;
        color: #54647F;
      }

      .value {
        font-size: 32px;
        line-height: 48px;
      }
    }
  }

  // .background-shape {
  //   position: absolute;
  //   width: 250px;
  //   height: 250px;
  //   left: -100px;
  //   top: -100px;
  //   background: #4BBA88;
  //   border-radius: 8px;
  //   transform: rotate(-45deg);
  //   z-index: 0;
  // }
}