.container {
  &.exercise-groups {
    margin: 30px;
    margin-bottom: 15px;
    font-size: 1.4em;

    .header {
      text-align: left;
    }

    .sub-header {
      text-align: left;
      margin-bottom: 10px;
      font-size: 0.7rem;
    }

    .content {
      width: 100%;
      overflow-x: scroll;
    }

    .exercise-groups {
      display: flex;
      flex-direction: row;
      overflow-x: scroll;
      gap: 10px;
      padding-bottom: 15px;

      .exercise-group {
        position: relative;
        padding: 20px;
        display: flex;
        flex-direction: row;
        height: 150px;
        width: 45%;
        min-width: 160px;
        max-width: 220px;
        background-color: rgb(154, 230, 180);
        border-radius: 8px;
        flex-shrink: 0;
        overflow: hidden;

        &:nth-child(1) {
          background-color: rgb(255, 215, 0);
        }

        &:nth-child(2) {
          background-color: rgb(192, 192, 192);
        }

        &:nth-child(3) {
          background-color: rgb(255, 160, 36);
        }

        .information {
          z-index: 1;
          width: 100%;

          .title {
            font-size: 20px;
          }

          .value {
            font-size: 50px;
            font-weight: 900;
          }
        }

        .position {
          position: absolute;
          top: 0;
          left: 0;
          padding: 20px;
          color: rgba(255, 255, 255, 0.4);
          font-weight: 900;
          font-size: 85px;
          height: 100%;
        }
      }
    }
  }
}
