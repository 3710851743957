@import '/src/App.scss';

.container {
  margin: 30px;
  font-size: 1.4em;
}

.avatar {
  position: absolute !important;
  top: 0;
  right: 0;
  margin: 30px !important;
  width: 40px !important;
  height: 40px !important;
  background-color: $webweights-green !important;

  svg {
    color: black;
  }
}