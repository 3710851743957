@import '/src/App.scss';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.access-container {
  background: $webweights-gray;
  height: 100vh;

  .background {
    position: fixed;
    top: 0;
    left: -100vw;
    height: 200vh;
    width: 200vw;
    transform: rotate(20deg);
    filter: blur(20px);
    opacity: 0.5;
  }
  
  .header {
    width: 40vw;
    padding: 40px;
    margin: 0 30vw;
    position: fixed;
    top: 100px;
    z-index: 99;

    img {
      margin: auto;
      max-height: 15vh;
    }
  }

  .page {
    height: 100vh;
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
  }
}