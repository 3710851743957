$webweights-green: #9AE6B4;
$webweights-gray: #edf2f7;

.App, .page {
  margin: auto;
  max-width: 550px;
}

.App {
  text-align: center;
  padding-top: 70px;
  margin-bottom: 90px;

}