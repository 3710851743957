.container {
  margin: 30px;
  font-size: 1.4em;

  &.recent-workouts {
    .header {
      text-align: left;
      margin-bottom: 10px;
    }

    .content {
      width: 100%;
      overflow-x: scroll;
    }
  }
}