.placeholder {
  width: 40px;
}

.tab-list {
  width: 100%;
  padding-left: 30px;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.tab-list::-webkit-scrollbar {
  display: none;
}

.workout-menu { 
  position: absolute !important;
  top: 0;
  right: 0;
  margin: 30px !important;
  width: 40px !important;
  height: 40px !important;
}

.tabs-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 30px;

  .actions {
    display: flex;
    flex-direction: row;
  }
}


.exercise-view {
  width: 100%;;
}

.workout-controls {
  .button {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-color: #9AE6B4;
    width: 100%;
    border-radius: 8px;
    height: 55px;
    display: flex;
    flex-direction: row;
    font-size: 16px;

    .icon {
      font-size: 16px;
    }

    span {
      padding: 10px;
    }
  }
}