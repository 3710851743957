.appbar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 250px;
  margin: auto;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.5);
  margin: 20px calc(50% - 125px);
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  overflow: hidden;
  z-index: 9999;

  .selected {
    background-color: #9AE6B4;
    height: 50px;
    width: 50px;
  }
}