@import '/src/App.scss';

body {
  background-color: $webweights-gray !important;
}

.exercise {
  background-color: $webweights-gray;
  overflow: hidden;

  .exercise-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    z-index: 1;
  }
  
  .group-name {
    height: 100%;
    padding: 20px;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 7vh;
    font-weight: bolder;
  }

  &.abs, &.arms, &.back, &.chest, &.legs, &.shoulders {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }

  &.abs {
    background-color: rgb(83, 107, 247);
    color: rgb(240, 242, 255);
  
    .group-name {
      color: rgb(138, 156, 255);
    }
  }

  &.arms {
    background-color: rgb(253, 94, 94);
  
    .group-name {
      color: rgb(255, 142, 142);
    }
  }
  
  
  &.back {
    background-color: rgb(82, 243, 189);
  
    .group-name {
      color: rgb(162, 255, 224);
    }
  }
  
  &.chest {
    background-color: rgb(250, 223, 74);
  
    .group-name {
      color: rgb(255, 240, 157);
    }
  }
  
  &.legs {
    background-color: rgb(255, 172, 47);
  
    .group-name {
      color: rgb(255, 215, 154);
    }
  }
  
  &.shoulders {
    background-color: rgb(195, 138, 255);
  
    .group-name {
      color: rgb(207, 161, 255);
    }
  }
}