.stat {
  &.container { 
    margin: 30px;
    margin-top: 15px;
    font-size: 1.4em;

    .header {
      text-align: left;
    }
    
    .sub-header {
      text-align: left;
      margin-bottom: 10px;
      font-size: 0.7rem;
    }

    .content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-items: stretch;
      align-items: stretch;
      column-gap: 10px;
      row-gap: 10px;
    
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgb(195, 138, 255);
        padding: 20px;
        border-radius: 8px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

        &:nth-child(2n) {
          background-color: rgb(253, 94, 94);
        }

        &:nth-child(3n) {
          background-color: rgb(82, 243, 189);
        }

        &:nth-child(4n) {
          background-color: rgb(255, 172, 47);
        }
      }
    }
  }
}
