.placeholder {
  width: 40px;
}

.set-stats-container {
  width: 100%;
}

.exercise-set-view {
  .exercise {
    padding: 30px;
    width: 100%;
    max-width: 550px;
    margin: auto;
    border-radius: 0 0 10px 10px;

    .exercise-name {
      width: 100%;
      text-align: left;
      font-size: 20px;
      font-weight: bold;
    }
  }

  .button {
    background-color: #9AE6B4;
    width: 100%;
  }
}

.sets-container {
  max-width: 550px;
  margin: auto;
}