@import '/src/App.scss';

.sign-in-container {
  display: flex;
  align-items: center;
  justify-content: center;

  .divider {
    width: 100%;
  }
}