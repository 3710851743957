.navbar {
  position: absolute;
  top: 0;
  width: 100%;
  max-width: 550px;

  .title {
    width: 100%;
    margin: auto;
    text-align: left;
    padding: 30px;
  }
}